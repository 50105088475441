.btn {
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 2px rgba($color: #000000, $alpha: 0.3);
    background-color: transparent;
    border: none;
    box-shadow: none;

    &:focus {
        border: none;
    }
}

.btn-primary {
    background-color: $color-primary;
    color: white;
    transition: background-color ease 0.3s;
    font-family: "Fredoka", sans-serif;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        background-color: darken($color: $color-primary, $amount: 15);
    }

    &:active{
        color: $color-primary;
        background-color: white;
        text-shadow: none;
        font-weight: bolder;
    }
}

.btn-shadow{
    box-shadow: 3px 3px rgba($color: #000000, $alpha: 0.2);
}

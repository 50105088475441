.home{
    width: 100%;
    margin-top: 10px;
    // min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    max-height: 720px;
}


.home-footer{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    color: white;
    width: 100%;
    bottom: 0;
    z-index: 1;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #fff;
    border-radius: 3px;
    box-shadow: 0px 2px rgba($color: #000, $alpha: 0.3);

    span{
        font-size: 0.9rem;
        margin-bottom: 10px;
    }
}

.home-icons{
    display: flex;
}

.home-icon{
    margin-inline: 5px;
    border-radius: 3px;
    display: flex;
    transition: background-color 0.3s ease;

    &:hover{
        background-color: darken($color: $color-primary, $amount: 20);
        // background-color: rgb(31, 31, 31);
    }
}


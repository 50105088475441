.nav-container{
    width: 20%;
    height: 95vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

.nav,
.nav-ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav {
    width: 100%;
    margin: auto;
    height: 100%;
    max-height: 720px;
}

.nav-ul {
    justify-content: space-around;
    min-height: 50%;
    width: 80%;
    transition: all 0.5s ease;
    position: absolute;
    top: 50%;
}

.nav-ul--focus{
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
}

.nav-ul--not-focus{
    opacity: 0;
    pointer-events: none;
    cursor: none;
    transform: translateY(-15vh);
}

.nav-item {
    width: 100%;
    text-align: center;
    color: white;
}

.nav-logo {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo h1 {
    font-size: 2rem;
    border-bottom: 3px solid white;
}
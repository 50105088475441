
body, html{
    font-family: 'Fredoka', sans-serif;
    background: radial-gradient(circle, rgba(14,82,53,1) 0%, rgba(46,55,51,1) 100%); 
    // background: radial-gradient(circle, rgba(27,41,124,1) 0%, rgba(16,25,75,1) 100%);    
}

.container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    max-width: 1280px;
}

.container-2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 0px 20px;
    min-height: 95vh;
}

.main-container{
    display: flex;
    align-items: center;
}


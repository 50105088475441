.skills {
    width: 100%;
}

.skills-section {
    min-width: 90%;
}

.skills-icon {
    font-size: 5rem;
    // width: 90%;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}

// .skills-icon--default{
//     width: 5rem;
//     height: 5rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border-radius: 10px;

//     .skills-icons__name{
//         font-size: 2.5rem;
//         text-shadow: 2px 2px rgba($color: #000000, $alpha: 0.3);
//     }
// }

.skills-item {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 25%;
    position: relative;
    display: flex;
    justify-content: center;
    &:hover .skills-icon {
        transform: rotateY(180deg);
        opacity: 0;
    }

    &:hover .skills-name {
        opacity: 1;
    }
}

.skills-name {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    opacity: 0;
    transition: all 0.5s ease;
    text-align: center;
}

.skills-name__link{
    border: 1px solid $color-primary;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
    // padding-inline: 5px;
    margin-top: 5px;
    color: rgb(59, 59, 59);
    text-shadow: 1px 1px rgba($color: #000000, $alpha: 0.3);
    transition: all 0.3s ease;

    &:hover{
        background-color: $color-secondary;
        color: white;
    }
}

.js {
    color: rgb(252, 238, 49);
}

.git {
    color: orange;
}

.mongo {
    color: rgb(4, 141, 11);
}

.react {
    color: rgb(9, 204, 204);
}

.html5 {
    color: orangered;
}

.css3 {
    color: rgb(16, 117, 212);
}

.sass {
    color: magenta;
}

.node {
    color: rgb(95, 197, 0);
}

.express {
    color: white;
    background-color: rgb(185, 185, 185);
}

.typescript{
    color: white;
    background-color: rgb(58, 77, 245);
}

$color-primary: #2DCC89;
$color-secondary: rgba(14,82,53,1);
$color-danger: #cf3625;
$color-info: rgb(34, 164, 224);

h1{
    font-size: 1.5rem;
    color: white;
}

h2{
    font-size: 1.3rem;
}

h3{
    font-size: 1.1rem;
}

.w-100{
    width: 100%;
}

.text-shadow{
    text-shadow: 0px 2px rgba($color: #000000, $alpha: 0.3);
}

.box-shadow{
    box-shadow: 0px 3px rgba($color: #000000, $alpha: 0.3);
}

.box-shadow-xy{
    box-shadow: 3px 3px rgba($color: #000000, $alpha: 0.3);
}

.mt-1{
    margin-top: 5px;
}

.mt-2{
    margin-top: 10px;
}

.mt-3{
    margin-top: 20px;
}

.mb-1{
    margin-bottom: 5px;
}

.mb-2{
    margin-bottom: 10px;
}

.mb-3{
    margin-bottom: 20px;
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

}

body, html{
    min-width: 100vw;
    min-height: 100vh;
}

li{
    list-style: none;
}

a{
    text-decoration: none;
    color: black;
}

img, video{
    width: 100%;
}
.contact {
    width: 100%;
}

.contact-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 30px 0px 30px 0px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 3px 3px rgba($color: #000000, $alpha: 0.3);
}

.contact-form input,
textarea,
button {
    margin-top: 10px;

    &:disabled {
        background-color: #e6e6e6;
        color: #929191;
    }
}

.contact-form__input-text {
    padding: 10px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid gray;
    border-radius: 2px;
    font-family: "Fredoka", sans-serif;
    font-size: 1rem;
    color: rgb(77, 76, 76);

    &:focus {
        outline: none;
        border-bottom: none;
        border: none;
        box-shadow: 0px 0px 3px 3px rgba(rgb(46, 156, 110), 0.9)
    }

    &::placeholder {
        color: $color-secondary;
    }
}

.contact-form textarea {
    resize: none;
}

.contact-form__label{
    color: white;
    padding: 10px;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    margin: 10px 0px 0px 0px;
}

.contact-form__label--error{
    background-color: $color-danger;
}

.contact-form__label--info{
    background-color: $color-info;
}

.about-me{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    min-height: 80vh;
}

p span{
    font-weight: bold;
    display: block;
}

@media screen and (max-width: 1080px) {
    .container {
        flex-wrap: wrap;
    }

    .container-2 {
        width: 90%;
        min-height: 85vh;
        padding: 0px;
    }

    .nav-container {
        width: 100%;
        height: 10vh;
        padding: 0px;
    }

    .nav__bars-menu {
        display: block;
    }

    .nav {
        height: 100%;
        width: inherit;
        align-items: flex-start;
        justify-content: center;
        background-color: darken($color: $color-primary, $amount: 10);
        box-shadow: 0px 2px 3px rgba($color: #000000, $alpha: 0.3);
    }

    .nav-ul {
        top: 10vh;
        width: 30%;
        height: 40vh;
        padding: 0px 20px 0px 20px;
        background-color: darken($color: $color-primary, $amount: 10);
        border-radius: 0px 0px 2px 2px;
        box-shadow: 2px 2px 3px rgba($color: #000000, $alpha: 0.3);
    }

    .nav-logo {
        margin-top: 0px;
        margin-left: 20px;
    }

    .home,
    .about-me,
    .proyects,
    .skills,
    .contact {
        width: 100%;
        align-items: center;
    }

    .text-shadow {
        text-shadow: 0px 1px rgba($color: #000000, $alpha: 0.3);
    }
}

@media screen and (max-width: 640px) {
    .nav-ul {
        width: 50%;
    }

    .card-container__img {
        width: 90%;
    }

    .proyects-img {
        a {
            font-size: 1rem;
        }
    }

    .card p {
        width: 90%;
    }

    .skills-item{
        width: 30%;
    }

    .contact-form {
        width: 90%;
    }
}

@media screen and (max-height: 640px) and (max-width: 1080px) and (orientation: landscape) {
    .nav {
        height: 13vh;
    }

    .nav-container {
        margin-bottom: 10px;
    }

    .nav-ul {
        top: 13vh;
        height: 60vh;
    }
}
